import Table from "react-bootstrap/Table";
import NavBar from "../../../NavBar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";

const ProductListing = () => {
  const [allproducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const getallProducts = () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${BASE_URL}admin/getProduct/${currentPage}`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setAllProducts(response.data.data);
          console.log(response.data.data);

          setPages(response.data.remainingCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteproduct = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {
    } else {
      return;
    }
    const token = localStorage.getItem("token");

    axios
      .delete(`${BASE_URL}admin/deleteProduct/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getallProducts();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getallProducts();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getallProducts(page);
  };

  return (
    <>
      <NavBar />
      <div className="container-fluid" >
        <div className="container">
          <div>
            <div className="row d-flex align-items-center">
              <div  className="col text-start">
                <span
                  style={{ fontSize: "20px", fontWeight: "600" }}
                  className="p-2 mt-3"
                >
                  Product Listing
                </span>
              </div>
              <div  className="col text-end">
                <button type="button" class="btn btn-primary btn-sm mt-3 mb-2 ">
                  <Link
                    to="/AddProduct"
                    style={{
                      color: "#fff",
                      fontSize: 20,
                      textDecoration: "none",
                    }}
                  >
                    Add<i className="fa fa-plus"></i>
                  </Link>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="" style={{overflow:"auto"}}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S no.</th>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Sub category</th>
                  <th>Home category</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allproducts.map((item, index) => (
                  <tr key={index}>
                    <td className="tabletd">{++index}</td>
                    <td>
                      <img src={item.image[0]} style={{ width: "40px" }} />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.category_details[0]?.name}</td>
                    <td>{item.subCategory_details[0]?.name}</td>
                    <td>{item.homeCategory_details[0]?.name}</td>
                    <td>{item.price}</td>
                    <td>{item.status}</td>

                    <td>
                      <button
                        type="button"
                        class="btn btn-primary  btn-sm"
                        onClick={() =>
                          navigate("/update-Product", { state: { item } })
                        }
                      >
                        <i className="fa fa-edit"></i>{" "}
                      </button>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        class="btn btn-primary btn-sm "
                        onClick={() =>
                          navigate("/product-view", { state: { item } })
                        }
                      >
                        <i className="fa fa-eye"></i>{" "}
                      </button>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        class="btn btn-danger btn-sm "
                        onClick={() => deleteproduct(item._id)}
                      >
                        <i className="fa fa-trash"></i>{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* pagination  */}
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
          </div>
        </div>
      </div>

         </>
  );
};
export default ProductListing;
